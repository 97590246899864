"use strict";


const baseURL = "https://yashsales-78198-ruby.b78198.dev.centralindia.az.svc.builder.ai";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL; 

// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from '../../components/src/registerServiceWorker';

const getBasename = path => path.substr(0, path.lastIndexOf('/'));

ReactDOM.render(
  <Router basename={getBasename(window.location.pathname)}>
    <App />
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();
